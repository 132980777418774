/**
 * PC 환경 브라우저에 출력 되는 서비스 화면 페이지를 정의 한 Routing 설정 파일입니다<p/>
 *
 * 화면의 Lazy Loading은 @loadable/component 컴포넌트를 사용 해서 처리 합니다<p/>
 * const Sample = loadable(() => import("pages/ ... "));
 *
 * @version 0.0.1
 * @author Taesung Park <pts@pineone.com>
 * @module system/shared/pc/routesOnPC
 * @see module:system/shared/mobile/routesOnMobile
 */

/*
1. route 속성 (react-router-dom) : https://reacttraining.com/react-router/web/api/Route, https://github.com/ReactTraining/react-router/blob/master/packages/react-router/docs/api/Route.md#location
  1) 기본 속성
    - component 
    - render : func
    - children: func
    - path: string | string[]
    - exact: bool
    - strict: bool
    - location: object
    - sensitive: bool
  2) 커스텀 속성
    - public : true 일 경우 별도 Role 체크를 수행 하지 않음
    - isHideHeader : true 일 경우 화면 헤더를 숨김

2. menu 속성 (react-metismenu) : https://www.npmjs.com/package/react-metismenu
  1) 기본 속성
    - icon : string, Icon class name of item
    - label : string, Label of item
    - to : string, Href address to link (if item has submenu, to property will be ignored)
    - externalLink : boolean, If true link opens page in new tab/window
    - content : Object[], Sub menu of item. (For Flat Contents you may use id and parentId properties instead.)
    - id : string | number, Necessary for Flat Contents, or useful when activating a link of menu contains non-unique links. Possbile values are; not empty string, or greater than 0 if it is number.
    - parentId : string | number, Necessary for Flat Contents. If item has no parent, top item, parentId should be falsy -one of false, undefined, null, empty string or number 0, not string "0".

  2) 커스텀 속성
    - isFilterMenu : true 일 경우 좌측 메뉴에는 추가하지 않고, 
    해당 정보로 '브레드크럼 네비게이션'이나 좌측 메뉴 포커싱(부모) 제어를 함 

3. permission 속성
  - 미정의 : 사업주/직원 접근 가능
  - PERMISSION_OWNER: 사업주만 접근 가능
*/

import loadable from "@loadable/component";

/*#############################################################
 * PC 기본 페이지
 *###########################################################*/
import NotFoundPage from "pages/NotFoundPage";
import LoginPage from "pages/signin/login/LoginPage";
const SignInSelectMotelPage = loadable(() =>
  import("pages/signin/motel/SignInSelectMotelPage")
);
const SignInNotFoundPage = loadable(() =>
  import("pages/signin/not-found/SignInNotFoundPage")
);
const FindIdAndPasswordPage = loadable(() =>
  import("pages/signin/find/FindIdAndPasswordPage")
);

const SignUpSelectUserPage = loadable(() =>
  import("pages/signup/user/SignUpSelectUserPage")
);
const TermsPage = loadable(() => import("pages/signup/terms/TermsPage"));
const RegisterPage = loadable(() =>
  import("pages/signup/register/RegisterPage")
);
const AlliancePage = loadable(() =>
  import("pages/signup/alliance/AlliancePage")
);

const CalendarPage = loadable(() =>
  import("pages/reservation_calendar/CalendarPage")
);
const ReservationPage = loadable(() =>
  import("pages/reservation_list/ReservationPage")
);
/*#############################################################
 * PC 서비스 페이지
 *###########################################################*/
/* eslint-disable import/first */
import MainPage from "pages/main/MainPage";
/* eslint-enable import/first */
const SettingsPage = loadable(() => import("pages/settingsV2/SettingsPage"));
const MyInfoPage = loadable(() => import("pages/mypageV2/myinfo/MyInfoPage"));

const SettlePage = loadable(() =>
  import("pages/reservation_settle/SettlePage")
);
const SurtaxPage = loadable(() => import("pages/surtax/SurtaxPage"));
const ReviewsPage = loadable(() => import("pages/reviews/ReviewsPageLayout"));
const DaychargePage = loadable(() => import("pages/daycharge/DaychargePage"));
const BusinessInfoPage = loadable(() =>
  import("pages/businessInfoV2/BusinessInfoPage")
);
const StandardChargeTimePage = loadable(() =>
  import("pages/standardChargeTime/StandardChargeTimePage")
);

const EventPage = loadable(() => import("pages/event/EventPage"));
const LowestPricePage = loadable(() => import("pages/cheapest/CheapestPage"));
const MileagePage = loadable(() =>
  import("pages/customerManagement/mileage/MileagePage")
);
const WishPageLayout = loadable(() =>
  import("pages/customerManagement/wish/WishPageLayout")
);
const ClickPageLayout = loadable(() =>
  import("pages/customerManagement/click/ClickPageLayout")
);
const CouponPublishLogLayout = loadable(() =>
  import("pages/customerManagement/publishLog/CouponPublishLogLayout")
);
const StatisticsSellingPage = loadable(() =>
  import("pages/statisticsSelling/StatisticsSellingPage")
);

const SerillayStayPage = loadable(() =>
  import("pages/seriallyStay/SeriallyStayPage")
);

const NoticePage = loadable(() => import("pages/notice/NoticePage"));

const FaqPage = loadable(() => import("pages/faq/FaqPage"));
const QuestionPage = loadable(() => import("pages/question/QuestionPage"));

const ServiceIntroPage = loadable(() =>
  import("pages/footer/ServiceIntroPage")
);
const TermIntroPage = loadable(() => import("pages/footer/TermIntroPage"));

const CouponCalendarPage = loadable(() =>
    import("pages/customerManagement/couponCalendar/CouponCalendarPage")
);
const FirstComeCouponPage = loadable(() =>
    import("pages/customerManagement/firstComeCoupon/FirstComeCouponPage")
);
const UnlimitedCouponPage = loadable(() =>
    import("pages/customerManagement/unlimitedCoupon/UnlimitedCouponPage")
);


/*#############################################################
 * Accessed Permission (액세스 권한)
 *###########################################################*/
/* eslint-disable import/first */
import * as configApp from "config/config-app";
import { URL_COOLSTAY_OFFICIAL_CAFE, URL_COOLSTAY_OFFICIAL_YOUTUBE } from "constant/URL";
/* eslint-enable import/first */
const {
  PERMISSION_OWNER,
  // PERMISSION_MANAGER
} = configApp;

const routesOnPC = [
  {
    route: {
      path: "/",
      exact: true,
    },
    /*
    20200325 박태성C : menu가 없는 화면으로 Routing 시 좌측 메뉴 비활성 처리

    1. 처리 플로우
      1) Routing 설정 파일(ex : routeOnPC, routesOnMobile)
          - id 가 1 인 menu 정의 
      
      2) DefaultLayoutContainer
          - 이벤트 리스닝 중 menu가 없는 화면으로 Routing 시 id 1 인 메뉴 활성화 처리
          - this.menuRef.changeActiveLinkId(1);

      3) /lib/service-react-metismenu-router-link/RouterLink.jsx
          - 'JS-HIDE'가 전달 되면 해당 메뉴를 그리지 않음
    */
    menu: {
      id: "JS-HIDE",
      label: "홈", // Breadcrumb 출력 시 사용
      to: "/",
    },
  },
  // (START) 대시보드 페이지
  {
    menu: {
      id: 100,
      //icon: 'metismenu-icon fa fa-bars',
      // icon: "fa fa-dashboard",
      icon: "fa icon-hm_btn_menu_home_n",
      label: "홈",
      to: "/dashboard",
    },
    route: {
      path: "/dashboard",
      exact: true,
      component: MainPage,
    },
  },
  //(END) 대시보드 페이지
  //(START) 설정
  {
    route: {
      path: "/settings",
      exact: true,
      component: SettingsPage,
    },
  },
  //(END) 설정
  //(START) 제휴 신청 하기
  {
    // 사업주와 웹마스타만 접근 가능
    permission: PERMISSION_OWNER,
    route: {
      path: "/alliance/add",
      exact: true,
      component: AlliancePage,
    },
  },
  //(END) 제휴 신청 하기
  //(START) 마이페이지
  {
    route: {
      path: "/mypage",
      exact: true,
      component: MyInfoPage,
    },
  },
  //(END) 마이페이지
  //(START) 푸터 서비스 소개
  {
    route: {
      path: "/serviceIntro",
      exact: true,
      component: ServiceIntroPage,
    },
  },
  //(END) 푸터 서비스 소개
  //(START) 푸터 약관, 개인정보처리 소개
  {
    route: {
      path: "/termIntro",
      component: TermIntroPage,
    },
  },
  //(START) 푸터 약관, 개인정보처리 소개
  //(START) 판매캘린더
  {
    menu: {
      id: 301,
      // parentId: 300,
      // icon: "fa fa-calendar",
      icon: "fa icon-hm_sub_btn_menu_calendar_p",
      label: "판매캘린더",
      to: "/calendar",
    },
    route: {
      path: "/calendar",
      exact: true,
      component: CalendarPage,
    },
  },
  //(END) 판매캘린더
  //(START) 예약내역
  {
    menu: {
      id: 302,
      // parentId: 300,
      // icon: "fa fa-inbox",
      // icon: "fa icon-hm_sub_btn_menu_list_reservation_n",
      icon: "fa icon-hm_btn_menu_reservation_f",
      label: "예약내역",
      to: "/reservation",
    },
    route: {
      path: "/reservation",
      exact: true,
      component: ReservationPage,
    },
  },
  //(END) 예약내역
  //(START) 고객후기
  {
    menu: {
      id: 1100,
      // parentId: 400,
      // icon: "fa fa-comments-o",
      icon: "fa icon-hm_sub_btn_menu_review_n",
      label: "고객후기", // 20200615 박태성C -`고객후기`에서 `후기`로 변경
      to: "/reviews",
    },
    route: {
      path: "/reviews",
      exact: true,
      component: ReviewsPage,
    },
  },
  //(END) 고객후기
  //(START) 정산관리
  {
    menu: {
      id: 300,
      // icon: "fa fa-calendar-check-o",
      // icon: "fa icon-hm_btn_menu_reservation_f",
      icon: "fa icon-hm_sub_btn_menu_list_n",
      label: "정산관리",
      to: "/sales",
    },
  },
  //(END) 정산관리
  //(START) 정산내역
  {
    // 사업주만 접근 가능
    // permission: PERMISSION_OWNER,
    menu: {
      id: 303,
      parentId: 300,
      // icon: "fa fa-krw",
      // icon: "fa icon-hm_sub_btn_menu_list_n",
      icon: "fa icon-hm_sub_btn_menu_list_reservation_n",
      label: "정산내역",
      to: "/settle",
    },
    route: {
      path: "/settle",
      exact: true,
      component: SettlePage,
    },
  },
  //(END) 정산내역
  //(START) 부가세신고
  {
    // 사업주만 접근 가능
    // permission: PERMISSION_OWNER,
    menu: {
      id: 304,
      parentId: 300,
      // icon: "fa fa-adjust",
      icon: "fa icon-hm_sub_btn_menu_calculator_n",
      label: "부가세신고",
      to: "/surtax",
    },
    route: {
      path: "/surtax",
      // exact: true,
      component: SurtaxPage,
    },
  },
  //(END) 부가세신고
  //(START) 제휴점관리
  {
    menu: {
      id: 400,
      // icon: "fa fa-building",
      icon: "fa icon-hm_btn_menu_motel_n",
      label: "제휴점관리", // 20200615 박태성C -`업소관리`에서 `제휴점관리`로 변경
      to: "/dummyBuizz",
    },
  },

  {
    menu: {
      id: 403,
      parentId: 400,
      // icon: "fa fa-info-circle",
      icon: "fa icon-hm_sub_btn_menu_setting_n",
      label: "숙소기본정보", // 20200615 박태성C -`업소정보관리`에서 `제휴점정보관리`로 변경
      to: "/businessInfo",
    },
    route: {
      path: "/businessInfo",
      exact: true,
      component: BusinessInfoPage,
    },
  },
  {
    menu: {
      id: 407,
      parentId: 400,
      // icon: "fa fa-clock-o",
      icon: "fa icon-hm_sub_btn_menu_time_n",
      label: "판매요금/시간",
      to: "/standardChargeTime",
    },
    route: {
      path: "/standardChargeTime",
      exact: true,
      component: StandardChargeTimePage,
    },
  },
  // 양태욱 (20210302) : 추후 기획 보강 후 오픈 예정
  {
    menu: {
      id: 402,
      parentId: 400,
      // icon: "fa fa-money",
      icon: "fa icon-hm_sub_btn_menu_extra_fee_n",
      label: "특정일요금",
      to: "/dayCharge",
    },
    route: {
      path: "/dayCharge",
      // exact: true,
      component: DaychargePage,
    },
  },
  {
    menu: {
      id: 408,
      parentId: 400,
      icon: "fa icon-hm_btn_menu_stay",
      label: "연박 추가 요금",
      to: "/seriallyStay",
    },
    route: {
      path: "/seriallyStay",
      //exact: true,
      component: SerillayStayPage,
    },
  },
  {
    menu: {
      id: 406,
      parentId: 400,
      // icon: "fa fa-crosshairs",
      icon: "fa icon-hm_sub_btn_menu_event_n",
      label: "이벤트",
      to: "/event",
    },
    route: {
      path: "/event",
      //exact: true,
      component: EventPage,
    },
  },
    // 꿀스테이 2.0 신규 메뉴
  {
    menu: {
      id: 410,
      parentId: 400,
      // icon: "fa fa-crosshairs",
      icon: "fa icon-hm_sub_btn_menu_price_n",
      label: "국내 최저가",
      to: "/lowestPrice",
    },
    route: {
      path: "/lowestPrice",
      //exact: true,
      component: LowestPricePage,
    },
  },
  //(END) 제휴점관리
  //(START) 고객관리
  {
    menu: {
      id: 500,
      icon: "fa icon-hm_btn_menu_customer_list_b",
      label: "쿠폰관리",
    },
  },
  {
    menu: {
      id: 501,
      parentId: 500,
      icon: "fa icon-hm_sub_btn_menu_coupon_calendar",
      label: "선착순 쿠폰 캘린더",
      to: "/couponCalendar",
    },
    route: {
      path: "/couponCalendar",
      exact: true,
      component: CouponCalendarPage,
    },
  },
  {
    menu: {
      id: 502,
      parentId: 500,
      icon: "fa icon-hm_sub_btn_menu_coupon_first-come",
      label: "선착순 쿠폰관리",
      to: "/firstComeCoupon",
    },
    route: {
      path: "/firstComeCoupon",
      exact: true, //중첩 라우트 사용시 주석처리할 것
      component: FirstComeCouponPage,
    },
  },
  {
    menu: {
      id: 503,
      parentId: 500,
      icon: "fa icon-hm_sub_btn_menu_coupon_unlimited",
      label: "무제한 쿠폰관리",
      to: "/UnlimitedCoupon",
    },
    route: {
      path: "/UnlimitedCoupon",
      exact: true, //중첩 라우트 사용시 주석처리할 것
      component: UnlimitedCouponPage,
    },
  },
  {
    menu: {
      id: 504,
      parentId: 500,
      icon: "fa icon-hm_sub_btn_menu_likey_b",
      label: "찜 쿠폰관리",
      to: "/wish",
    },
    route: {
      path: "/wish",
      exact: true, //중첩 라우트 사용시 주석처리할 것
      component: WishPageLayout,
    },
  },
  {
    menu: {
      id: 505,
      parentId: 500,
      icon: "fa icon-hm_sub_btn_menu_reservation_b",
      label: "재방문 쿠폰관리",
      to: "/mileage",
    },
    route: {
      path: "/mileage",
      exact: true, //중첩 라우트 사용시 주석처리할 것
      component: MileagePage,
    },
  },
  {
    menu: {
      id: 506,
      parentId: 500,
      icon: "fa icon-hm_sub_btn_menu_download_b",
      label: "첫예약 쿠폰관리",
      to: "/click",
    },
    route: {
      path: "/click",
      exact: true, //중첩 라우트 사용시 주석처리할 것
      component: ClickPageLayout,
    },
  },
  {
    menu: {
      id: 507,
      parentId: 500,
      icon: "fa icon-hm_sub_btn_menu_couponlist_b",
      label: "쿠폰 발행내역",
      to: "/couponlog",
    },
    route: {
      path: "/couponlog",
      exact: true, //중첩 라우트 사용시 주석처리할 것
      component: CouponPublishLogLayout,
    },
  },
  //(END) 고객관리
  //(START) 통계
  {
    menu: {
      id: 600,
      // icon: "fa fa-pie-chart",
      icon: "fa icon-hm_btn_menu_graph_n",
      label: "통계",
      to: "/statisticsSelling",
    },
    route: {
      path: "/statisticsSelling",
      exact: true,
      component: StatisticsSellingPage,
    },
  },
  // {
  //   menu: {
  //     id: 601,
  //     parentId: 600,
  //     icon: "fa fa-shopping-bag",
  //     label: "통계 보고서",
  //     to: "/statisticsSelling",
  //   },
  //   route: {
  //     path: "/statisticsSelling",
  //     exact: true,
  //     component: StatisticsSellingPage,
  //   },
  // },
  // {
  //   menu: {
  //     id: 602,
  //     parentId: 600,
  //     icon: "fa fa-rss",
  //     label: "후기",
  //     to: "/statisticsReview",
  //   },
  //   route: {
  //     path: "/statisticsReview",
  //     component: StatisticsReviewPage,
  //   },
  // },
  //(END) 통계
  //(START) 파트너센터
  {
    menu: {
      id: 700,
      // icon: "fa fa-users",
      icon: "fa icon-hm_btn_menu_center_n",
      label: "파트너센터",
      to: "/dummyCustom",
    },
  },
  {
    menu: {
      id: 701,
      parentId: 700,
      // icon: "fa fa-bullhorn",
      icon: "fa icon-hm_sub_btn_menu_notice_n",
      label: "공지사항",
      to: "/notice",
    },
    route: {
      path: "/notice",
      //exact: true,
      component: NoticePage,
    },
  },
  {
    menu: {
      id: 702,
      parentId: 700,
      // icon: "fa fa-commenting",
      icon: "fa icon-hm_sub_btn_menu_faq_n",
      label: "FAQ",
      to: "/faq",
    },
    route: {
      path: "/faq",
      // exact: true,
      component: FaqPage,
    },
  },
  {
    menu: {
      id: 703,
      parentId: 700,
      // icon: "fa fa-question-circle",
      icon: "fa icon-hm_sub_btn_menu_inquiry_n",
      label: "1:1 문의",
      to: "/question",
    },
    route: {
      path: "/question",
      // exact: true,
      component: QuestionPage,
    },
  },
  //(START) 외부 카페 이동
  {
    menu: {
      id: 800,
      // icon: "fa fa-pie-chart",
      icon: "fa icon-hm_sub_btn_menu_cafe1",
      label: "꿀스테이 공식 카페",
      to: URL_COOLSTAY_OFFICIAL_CAFE,
      externalLink: true,
    },
  },

  {
    menu: {
      id: 900,
      // icon: "fa fa-pie-chart",
      icon: "fa icon-hm_sub_btn_menu_TV",
      label: "꿀스테이 TV",
      to: URL_COOLSTAY_OFFICIAL_YOUTUBE,
      externalLink: true,
    },
  },

  //(END) 외부 카페 이동
  // 20210312 김주호, [PLATFORM-699] 이벤트 메뉴 삭제
  // {
  //   menu: {
  //     id: 704,
  //     parentId: 700,
  //     // icon: "fa fa-crosshairs",
  //     icon: "fa icon-hm_sub_btn_menu_event_n",
  //     label: "이벤트",
  //     to: "/customerEvent",
  //   },
  //   route: {
  //     path: "/customerEvent",
  //     // exact: true,
  //     component: CustomerEventPage,
  //   },
  // },
  //(END) 파트너센터
  //(START) 매물거래
  // {
  //   menu: {
  //     id: 800,
  //     icon: "fa icon-hm_sub_btn_menu_deal_n",
  //     label: "매물거래"
  //     // to: "/dummyItem",
  //   }
  // },
  // {
  //   menu: {
  //     id: 801,
  //     parentId: 800,
  //     // icon: "fa fa-bullhorn",
  //     icon: "fa icon-hm_sub_btn_menu_notice_n",
  //     label: "공지사항",
  //     to: "/realEstateNotice"
  //   },
  //   route: {
  //     path: "/realEstateNotice",
  //     component: RealEstateNoticePage
  //   }
  // },
  // {
  //   menu: {
  //     id: 802,
  //     parentId: 800,
  //     // icon: "fa fa-info-circle",
  //     icon: "fa icon-hm_sub_btn_menu_deal_n",
  //     label: "매물거래",
  //     to: "/realEstateInfo"
  //   },
  //   route: {
  //     path: "/realEstateInfo",
  //     component: RealEstateInfoPage
  //   }
  // }

  //(END) 매물거래
  //(START) 비품구매
  // {
  //   menu: {
  //     id: 900,
  //     icon: "fa fa-shopping-cart",
  //     label: "비품구매",
  //     to: "/dummyBuy",
  //   },
  // },
  // {
  //   menu: {
  //     id: 901,
  //     parentId: 900,
  //     icon: "fa fa-info-circle",
  //     label: "물품정보",
  //     to: "/shopItemInfo",
  //   },
  //   route: {
  //     path: "/shopItemInfo",
  //     exact: true,
  //     component: ShopItemInfoPage,
  //   },
  // },
  // {
  //   menu: {
  //     id: 902,
  //     parentId: 900,
  //     icon: "fa fa-credit-card",
  //     label: "주문내역",
  //     to: "/shopOrderInfo",
  //   },
  //   route: {
  //     path: "/shopOrderInfo",
  //     exact: true,
  //     component: ShopOrderInfoPage,
  //   },
  // },

  //(END) 비품구매
  //(START) 샘플
  // {
  //   menu: {
  //     id: 1000,
  //     icon: "metismenu-icon fa fa-bars",
  //     label: "Samples",
  //     to: "/dummySample",
  //   },
  // },
  // {
  //   menu: {
  //     id: 1001,
  //     parentId: 1000,
  //     icon: "metismenu-icon fa fa-bars",
  //     label: "무한 달력",
  //     to: "/samples/infinitCalendar",
  //   },
  //   route: {
  //     path: "/samples/infinitCalendar",
  //     exact: true,
  //     component: TestCalendarComp,
  //   },
  // },
  // {
  //   menu: {
  //     id: 1002,
  //     parentId: 1000,
  //     icon: "metismenu-icon fa fa-bars",
  //     label: "원형 슬라이더",
  //     to: "/samples/sircleSlider",
  //   },
  //   route: {
  //     path: "/samples/sircleSlider",
  //     exact: true,
  //     component: TestCircleSliderComp,
  //   },
  // },
  // {
  //   menu: {
  //     id: 1003,
  //     parentId: 1000,
  //     icon: "metismenu-icon fa fa-bars",
  //     label: "이미지 갤러리",
  //     to: "/samples/imageGallery",
  //   },
  //   route: {
  //     path: "/samples/imageGallery",
  //     exact: true,
  //     component: TestGalleryComp,
  //   },
  // },
  // {
  //   menu: {
  //     id: 1004,
  //     parentId: 1000,
  //     icon: "metismenu-icon fa fa-bars",
  //     label: "데이타 테이블",
  //     to: "/samples/dataTables",
  //   },
  //   route: {
  //     path: "/samples/dataTables",
  //     exact: true,
  //     component: TestTableComp,
  //   },
  // },
  // {
  //   menu: {
  //     id: 1005,
  //     parentId: 1000,
  //     icon: "metismenu-icon fa fa-bars",
  //     label: "Ajax 연동",
  //     to: "/samples/ajax",
  //   },
  //   route: {
  //     path: "/samples/ajax",
  //     exact: true,
  //     component: TestAPI,
  //   },
  // },
  // {
  //   menu: {
  //     id: 1006,
  //     parentId: 1000,
  //     icon: "metismenu-icon fa fa-bars",
  //     label: "ServiceCode 조회",
  //     to: "/samples/servicecode",
  //   },
  //   route: {
  //     path: "/samples/servicecode",
  //     exact: true,
  //     component: TestServiceCode,
  //   },
  // },
  //(END) 샘플
];

export {
  // PC 서비스 페이지
  routesOnPC,
  // 404 Not Found Page
  NotFoundPage,
  // 로그인 관련 페이지
  LoginPage,
  SignInSelectMotelPage,
  SignInNotFoundPage,
  // 회원 가입 관련 페이지
  SignUpSelectUserPage,
  RegisterPage,
  TermsPage,
  AlliancePage,
  // 아이디 및 비밀번호 변경/찾기
  FindIdAndPasswordPage,
};

/**
 * 본 모듈은 `비밀번호`와 `비밀번호 확인`이 같은지 체크 하는 모듈 입니다<p/>
 *
 * @module utils/checkPasswordConfirm
 */

import InputFieldGuide from "constant/InputFieldGuide";
import isEmpty from "./isEmpty";

/**
 * 비밀번호 확인을 검사하여, 유효하지 않을 경우 에러 메세지를 반환 합니다
 *
 * @param {string} newPassword - 새로운 비밀번호
 * @param {string} passwordConfirm - 비밀번호 확인
 * @return {string} 에러 메세지. 유효 할 경우 빈문자열을 반환 함
 */

export default function checkPasswordConfirm(newPassword, passwordConfirm) {
  let errorMessage = "";
  let strPasswordConfirm = passwordConfirm.trim();
  let strNewPassword = newPassword.trim();

  // `비밀번호 확인` 공백 검사
  if (!errorMessage && isEmpty(strPasswordConfirm) === true) {
    errorMessage = InputFieldGuide.passwordConfirm.required;
  }

  // `비밀번호`와 다른지 검사
  if (
    !errorMessage &&
    isEmpty(strPasswordConfirm) === false &&
    strPasswordConfirm !== strNewPassword
  ) {
    errorMessage = InputFieldGuide.passwordConfirm.validate;
  }

  return errorMessage;
}

/**
 * 본 모듈은 이메일 형식이 유효한지 체크 하는 모듈 입니다<p/>
 *
 * @module utils/checkEmail
 */

import InputFieldGuide from "constant/InputFieldGuide";
import isEmpty from "./isEmpty";
import isEmailFormat from "./isEmailFormat";

/**
 * 이메일을 검사하여, 유효하지 않을 경우 에러 메세지를 반환 합니다
 *
 * @param {string} email - 이메일
 * @return {string} 에러 메세지. 유효 할 경우 빈문자열을 반환 함
 */

export default function checkEmail(email) {
  let errorMessage = "";
  let strEmail = email.trim();

  // email 공백 검사
  if (!errorMessage && isEmpty(strEmail) === true) {
    errorMessage = InputFieldGuide.email.required;
  }

  // email 형식 검사
  if (!errorMessage && isEmailFormat(strEmail) === false) {
    errorMessage = InputFieldGuide.email.invalid;
  }

  return errorMessage;
}

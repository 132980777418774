import React from "react";

import { withStyles, withTheme } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import CssBaseline from "@material-ui/core/CssBaseline";

import { ContainerWrap } from "./ContainerWrap";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { logoutRequest } from "system/redux-ducks/authentication";

import { history } from "lib/history";
import * as configApp from "config/config-app";

let SERVICE_TYPE_PC = configApp.SERVICE_TYPE === "pc";
let WIDTH_CONTAINER = "38.125rem"; // 610px
let PADDING_TOP_CONTAINER = "8.125rem !important"; // 130px
let WIDTH_BOX_INNER = "25.5rem"; // 408px
let POSITION = "relative";
if (SERVICE_TYPE_PC === false) {
  WIDTH_CONTAINER = "100%";
  PADDING_TOP_CONTAINER = "0";
  WIDTH_BOX_INNER = "90%";
  POSITION = "absolute";
}

// 16px 기준으로 rem 테이블 정리 사이트 : http://www.standardista.com/px-to-rem-conversion-if-root-font-size-is-16px/
// rem을 px로 변환 해 주는 사이트 : https://www.ninjaunits.com/converters/pixels/rem-pixels/
const styles = (theme) => ({
  root: {
    position: "relative",
    width: "100%",
    "& .csp": {
      width: WIDTH_CONTAINER,
      paddingTop: PADDING_TOP_CONTAINER,
      paddingLeft: "0",
      paddingRight: "0",
      "& .csp-title > *": {
        fontSize: "1rem", // 16px
        fontWeight: "bold",
        lineHeight: "3rem", // 48px
        letterSpacing: "-0.05rem", // -0.8px
        textAlign: "center",
        color: "#222222",
        marginTop: 0,
        marginBottom: 0,
      },
      "& .csp-box-outter": {
        width: "100%",
      },
      "& .csp-box-inner": {
        maxWidth: "100%",
        paddingLeft: "0",
        paddingRight: "0",
        margin: 0,
        "& .font-size-16": {
          fontSize: "1rem", // 16px
        },
        "& .description": {
          width: WIDTH_CONTAINER,
          display: "table-cell",
          verticalAlign: "middle",
        },
        "& .description *": {
          textAlign: "center",
        },
        "& .description *.bold": {
          fontWeight: "bold",
        },
        "& .wrap-inner": {
          width: WIDTH_BOX_INNER,
        },
        "& .line-bottom": {
          marginTop: "0.625rem", // 10px
          marginBottom: "0.625rem", // 10px
        },
        "& .info *": {
          // opacity: 0.5,
          fontSize: "0.625rem", // 10px
          fontWeight: "bold",
          color: "#000000",
        },
        "& .boxField": {
          marginTop: "1.25rem", // 20px
        },
        "& .boxFieldText *": {
          fontSize: "0.813rem", // 13px
        },
        "& table": {
          border: "1px solid #ddd",
          tableLayout: "fixed",
          borderCollapse: "collapse",
          "& th, & .likeTh": {
            background: "#f8f8f9",
            fontWeight: "bold",
          },
          "& .likeTh": {
            width: "20%",
            "& *": {
              display: "inline-block",
            },
            "& .required": {
              color: "red",
            },
            "& .optional": {
              color: "blue",
            },
            "&.noPaddingSide": {
              padding: '8px 0 !important'
            }
          },
          "& .likeThMainColumn": {
            width: "30%",
            minHeight: "150px",
            fontWeight: "bold",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            border: "0px solid #ddd",
            background: "#f8f8f9",
            borderRight: "1px solid #ddd",
          },
          "& .contentCell": { padding: "0 !important" },

          "& td": {
            border: "1px solid #ddd",
            // lineHeight: "30px",
          },
        },
        "& .subtr": { padding: "0 !important" },
        "& .subtable": {
          tableLayout: "fixed",
          "& td": {
            borderLeft: "none",
          },
        },
      },
    },
  },
});

/**
 * 싱글 페이지의 Layout Component 입니다.<p/>
 *
 * @component
 * @name ContainerSinglePage
 * @author Taesung Park <pts@pineone.com>
 */
class ContainerSinglePage extends React.PureComponent {
  constructor(props) {
    super(props);
    this.handleOnBack = this.handleOnBack.bind(this);
  }

  handleOnBack() {
    this.props.logoutRequest().then(() => {
      history.replace({ pathname: "/login" });
    });
  }

  render() {
    const { classes, children, title, isHideBack } = this.props;
    return (
      <div className={classes.root}>
        <Container className="csp">
          <CssBaseline />

          {SERVICE_TYPE_PC && !!title && (
            <>
              <Box className="csp-title">
                <h3>{title}</h3>
              </Box>
              <Divider />
            </>
          )}

          {!SERVICE_TYPE_PC && !!title && (
            <ContainerWrap
              title={title}
              onBack={this.handleOnBack}
              isHideBack={isHideBack}
            />
          )}

          <div className="csp-box-outter">
            <Box display="flex" justifyContent="center">
              <Box className="csp-box-inner">{children}</Box>
            </Box>
          </div>
        </Container>
      </div>
    );
  }
}

export default connect(null, (dispatch) =>
  bindActionCreators({ logoutRequest }, dispatch)
)(withTheme(withStyles(styles)(ContainerSinglePage)));

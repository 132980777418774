/**
 * @module
 * @name URL
 * @description 앱에서 사용하는 URL을 정의 한 모듈 입니다
 */

/** -----------------공통----------------- */

/** 꿀스테이 파트너센터 공식카페 URL*/
export const URL_COOLSTAY_OFFICIAL_CAFE = "https://bit.ly/coolcafe";

/** 꿀스테이 YOUTUBE URL*/
export const URL_COOLSTAY_OFFICIAL_YOUTUBE = "https://app.wisetracker.co.kr/20000/sld.do?trackId=M00200001719792147202";


/** -----------------업주M 전용----------------- */

/** 업주M > 마이페이지 > 제휴점정보 */
export const URL_AFFILIATE_INFO = "/affiliateInfo";

/** 업주M > 마이페이지 > 비밀번호변경 */
export const URL_PASSWORD_CHANGE = "/password/change";

/**
 * 본 모듈은 비밀번호 형식이 유효한지 체크 하는 모듈 입니다<p/>
 *
 * @module utils/checkPassword
 */

import InputFieldGuide from "constant/InputFieldGuide";
import isEmpty from "./isEmpty";

/**
 * 비밀번호 형식을 검사하여, 유효하지 않을 경우 에러 메세지를 반환 합니다
 *
 * @param {string} password - 비밀번호
 * @param {string} userId - 사용자 아이디.
 * @return {string} 에러 메세지. 유효 할 경우 빈문자열을 반환 함
 */

export default function checkPassword(password, userId) {
  let errorMessage = "";
  let strPassword = password.trim();

  // 비밀번호 공백 검사
  if (!errorMessage && isEmpty(strPassword) === true) {
    errorMessage = InputFieldGuide.password.required;
  }

  // 영문, 숫자, 특수문자 (!$%^) 존재하는지 검사
  if (!errorMessage) {
    let tmpPassword = strPassword.replace(
      /[a-zA-Z0-9\`~!@\#$%^&*\(\)\-_\=+\[\]\{\}\\|;:'",<.>\/?]/gi,
      ""
    );
    // 공백이 아닐 경우 에러 처리
    if (tmpPassword) {
      errorMessage = InputFieldGuide.password.pattern;
    }
  }

  // 영문, 숫자, 특수문자 여부를 판단하여 자릿수 검사
  if (!errorMessage) {
    // https://java119.tistory.com/71
    const num = strPassword.search(/[0-9]/gi);
    const eng = strPassword.search(/[a-zA-Z]/gi);
    // const spe = strPassword.search(/[!$%^]/gi);
    const spe = strPassword.search(
      /[\`~!@\#$%^&*\(\)\-_\=+\[\]\{\}\\|;:'",<.>\/?]/gi
    );

    const len = strPassword.length;
    // 숫자/영문/특수문자 중 3가지 조합
    if (-1 < num && -1 < eng && -1 < spe) {
      // 길이가 안맞을 경우 에러 처리
      if (len < 8 || len > 20) {
        errorMessage = InputFieldGuide.password.min8AndMax20;
      }
    }
    // 숫자/영문/특수문자 중 2가지 조합
    else if (
      (-1 < num && -1 < eng && -1 === spe) ||
      (-1 < num && -1 === eng && -1 < spe) ||
      (-1 === num && -1 < eng && -1 < spe)
    ) {
      // 길이가 안맞을 경우 에러 처리
      if (len < 10 || len > 20) {
        errorMessage = InputFieldGuide.password.min10AndMax20;
      }
    }
    // 숫자/영문/특수문자 중 1가지 조합
    else {
      errorMessage = InputFieldGuide.password.pattern;
    }
  }

  /* 
    비밀번호 연속 숫자 검사 (2021년 1월 29일 기준)

    - 동일 한 숫자는 3자리 부터 불가 : ex : 111, 222, 1111, 2222
    - 연속적인 숫자는 3자리 부터 불가 : ex :   123, 456, 1234, 4567
    - 역순으로 연속적인 숫자는 3자리 부터 불가 : ex :   321, 654, 4321, 7654
  */
  // if (!errorMessage && stck(strPassword, 3) === false) {
  //   errorMessage = InputFieldGuide.password.sequenceNumber;
  // }

  // 아이디와 같은지 검사
  if (!errorMessage && isEmpty(userId) === false) {
    if (strPassword === userId.trim()) {
      errorMessage = InputFieldGuide.password.equalsUserId;
    }
  }

  return errorMessage;
}

/*
https://ejnahc.tistory.com/31

입력한 문자열을 처음부터 끝까지 한문자씩 탐색하면서, 
이전문자와 현재문자의 아스키 값 차이값이 -1, 0, 1 범위안에 있으면 카운트하고 
탐색하다가 카운트값이 제한값을 넘으면 오류로 보는방식입니다. 

c:현재문자 아스키 코드 
o:c의 이전상태 백업, 이전문자의 아스키 코드가 된다. 
p:c와 o의 아스키 코드 차이값으로 이값이 -1, 0, 1이면 이웃한 두 문자가 연속이라는 의미 
d:p의 이전상태 백업 p와 d가 동일하다면 연속이 계속된다는 의미임으로 카운트한다. 
n:p와 d가 같을때마다 카운트한 값으로, p와 d가 다르면 0으로 초기화한다. 
l:연속으로 등장해도 되는 제한값이고 사용자가 입력치 않으면 4가 로 세트.

*/
function stck(str, limit) {
  let o, d, p, c;
  let n = 0;
  let l = limit == null ? 4 : limit;
  let i = 0;
  let len = str.length;
  for (; i < len; i++) {
    c = str.charCodeAt(i);
    if (48 <= c && 58 > c) {
      if (
        i > 0 &&
        (p = o - c) > -2 &&
        p < 2 &&
        (n = p == d ? n + 1 : 0) > l - 3
      ) {
        return false;
      }
      d = p;
      o = c;
    } else {
      d = o = undefined;
      n = 0;
    }
  }
  return true;
}

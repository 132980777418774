import React from "react";

import { withStyles } from "@material-ui/core/styles";

import Box from "@material-ui/core/Box";

import { FooterContainer, Footer } from "components";
import ContainerSinglePage from "components/ContainerSinglePage";
import LoginTemplate from "../components/templates/LoginTemplate";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { history } from "lib/history";

import {
  POPUP_ACTION_OK,
  openHttpRejectionPopupWithPromise,
  openAlertPopupWithPromise,
  openConfirmPopupWithPromise,
} from "lib/helper-popup";

import { getServiceCodeUserStatus } from "constant/ServiceCode";

import {
  loginRequest,
  loginRequestByToken,
  logoutRequest,
} from "system/redux-ducks/authentication";
import { openSnackbar } from "lib/service-redux-alerts";

import * as configApp from "config/config-app";
import * as serviceStorage from "lib/service-storage";
import * as serviceBrowser from "lib/service-browser";
import encrypt from "../../../utils/encrypt";
import {postTempToken} from "system/redux-ducks/actions/tempTokenAction";
import {postRSA, setRsaInitial} from "../../../system/redux-ducks/actions/rsaAction";
import {getAutoLoginData, getIdentity} from "lib/service-storage";

const USER_STATUS_OK = getServiceCodeUserStatus("정상");
const USER_STATUS_CHANGED_INFO = getServiceCodeUserStatus(
  "정상 - 사용자정보변경"
);
const USER_STATUS_FIRST_LOGIN = getServiceCodeUserStatus("첫 로그인");
const USER_STATUS_REQUIRED = getServiceCodeUserStatus("대기 - 필수정보 미입력");
const USER_STATUS_WAIT_AllIANCE = getServiceCodeUserStatus("대기 - 승인대기");

const MESSAGE_GREETING_LOGIN = "정상 로그인 되었습니다";
const SERVICE_TYPE_PC = configApp.SERVICE_TYPE === "pc";
const ROOT_PATH = configApp.getPathRoot();

const TAG = "LoginPageLayout";

// 16px 기준으로 rem 테이블 정리 사이트 : http://www.standardista.com/px-to-rem-conversion-if-root-font-size-is-16px/
// https://nekocalc.com/px-to-rem-converter

/*
Material-ui Media Query 관련 참고
https://material.io/resources/devices/
https://material-ui.com/customization/breakpoints/
https://developer.mozilla.org/ko/docs/Web/Guide/CSS/Media_queries
https://stackoverflow.com/questions/52150419/material-ui-breakpoints-to-set-orientation-portrait-and-landscape-view
*/
const styles = (theme) => {
  if (!SERVICE_TYPE_PC) {
    // 모바일
    return {
      root: {
        // AppOnMobile에서 body에 landscape를 등록 할 경우 하단 스타일이 적용 되도록 수정
        "body.landscape & .csp .csp-box-outter": {
          paddingTop: "10% !important",
        },
        "& .csp .csp-box-outter": {
          // 20201014 박태성C - 여백이 발생하는 현상이 있어 적용
          overflow: "hidden",
          paddingTop: "30% !important",
        },
        "& .main": {
          width: "16.875rem",
        },
        "& .main img": {
          width: "100%",
          height: "auto",
        },
      },
    };
  }

  // PC
  return {
    root: {
      "& .csp": {
        // paddingTop: "166px !important",
        paddingTop: "110px !important",
      },
      "& .main": {
        width: "16.875rem",
      },
      "& .main img": {
        height: "196px",
      },
    },
  };
};

let TIMER = null;

/**
 * 로그인 페이지의 Layout Component 입니다.<p/>
 *
 * @author Taesung Park <pts@pineone.com>
 * @name FindIdPassPageLayout
 * @class
 * @component
 */

class LoginPageLayout extends React.PureComponent {
  constructor(props) {
    super(props);
    serviceStorage.removeAcceptTerms();
    serviceStorage.resetTempData();

    this.landingPage = serviceStorage.getLandingPageOnce();
    this.handleOnSubmit = this.handleOnSubmit.bind(this);
    this.handleSuccessLogin = this.handleSuccessLogin.bind(this);
    this.handleFailLogin = this.handleFailLogin.bind(this);
    this.handleFailedAutoLogin = this.handleFailedAutoLogin.bind(this);
    this.delayShowToast = this.delayShowToast.bind(this);
    !!TIMER && clearTimeout(TIMER);
    TIMER = null;

    this.state = {
      isStartingAutoLogin: !!serviceStorage.getAutoLoginData(),
    };

    this.isActive = true;
  }
  componentDidMount() {
    console.log(TAG, "Called componentDidMount");

    serviceBrowser.addClassNameToBody("logout");

    // 모바일 - 로컬스토리지에 저장된 정보가 있으면, 자동 로그인 처리함
    if (this.state.isStartingAutoLogin === true) {
      TIMER = setTimeout(() => {
        !!TIMER && clearTimeout(TIMER);
        TIMER = null;

        if (!this.isActive) return;

        console.log(TAG, "Start Auto Login");
        this.props
          .loginRequestByToken(serviceStorage.getAutoLoginData())
          .then(this.handleSuccessLogin)
          .catch(this.handleFailLogin);
      }, 100);
    }
  }

  componentWillUnmount() {
    console.log(TAG, "Called componentWillUnmount");
    this.isActive = false;
  }

  delayShowToast(message) {
    console.log(TAG, "Called delayShowToast");

    TIMER = setTimeout(() => {
      !!TIMER && clearTimeout(TIMER);
      TIMER = null;
      try {
        this.props.openSnackbar({
          message: message,
        });
      } catch (e) {}
    }, 100);
  }
  isUsablePublicKey (publicKey)  {
    return publicKey !== '' && publicKey !== null && publicKey !== undefined
  }
  async handleOnSubmit (params) {
    console.log(TAG, "Called handleOnSubmit");
    let { userId, userPassword, isAutoLogin, isSaveID } = params;

    /*
    20200922 박태성 - JIRA PLATFORM-41
    `로그인 유지`는 사용자의 선택에 의해서 진행되어야 
    추후 고객과 분쟁을 줄일 수 있다는 파인스테이 요청이 있어 
    모바일 버전도 노출 되도록 수정 함
    */

    // if (!SERVICE_TYPE_PC) {
    //   isAutoLogin = true;
    // }
    const isUsableKey = this.isUsablePublicKey(this.props.publicKey);

    if(!isUsableKey) {
      await this.props.postRSA({})
          .then(() => {
            console.log(this.props.publicKey)
          })
    }
    await this.props.postTempToken()
        .then(()=>{

          const params = {
            token : {
              tempToken : this.props.tempToken,
            },
            data : {
              id : userId,
              rsaKey : this.props.rsaKey,
              rsaEncPwd : encrypt(this.props.publicKey, userPassword),
            },
            isAutoLogin,
            isSaveID
          }
          this.props
              .loginRequest(params)
              .then(this.handleSuccessLogin)
              .catch(this.handleFailLogin);
        })
        .catch((error) => {
         console.error("Failed to fetch temporary token:", error);
       })
  }

  handleSuccessLogin() {
    console.log(TAG, "Called handleSuccessLogin");
    this.props.setRsaInitial();
    let routeNext;
    let routeNextLogout;
    let identity = serviceStorage.getIdentity();
    let motels = serviceStorage.getMotels(identity);
    let motelKey;

    let isShownToast = false;
    let messageAlert;
    let isStartService = false;

    const motelLen = motels.length;
    const isOwner = serviceStorage.isOwner(identity);
    let userStatus = serviceStorage.getUserStatus(identity);

    if (1 < motelLen) {
      // 등록 된 제휴점이 여러 개일 경우 `제휴점 선택 화면`으로 이동 한다.
      routeNext = { pathname: "/login-motel" };
    } else if (1 === motelLen) {
      // if (isOwner) {
      //   // 사업주에게 `추가 제휴 신청하기` 기능을 제공하기 위해
      //   // 등록된 제휴점이 한 개라도 `제휴점 선택 화면`으로 이동 한다.
      //   routeNext = { pathname: "/login-motel" };
      // } else {
      //   // 사업주를 제외한 계정의 (직원/매니저/관리자)의 경우 등록된 제휴점이 한 개라면 `대쉬보드` 화면으로 이동 한다.
      //   isShownToast = true;
      //   messageAlert = MESSAGE_GREETING_LOGIN;
      //   isStartService = true;
      //   routeNext = { pathname: "/" };
      //   motelKey = serviceStorage.getMotels(identity)[0].key;
      // }

      /* 
      ---------------------------------------------------------
      JIRA PLATFORM-49 모텔이 하나인 아이디 처리
      ---------------------------------------------------------

      1. 업주 PC, 업주 Mobile
        1) 숙소가 하나인 계정으로 로그인 시 바로 홈 화면으로 이동 처리(숙소가 여러 개인 경우 제휴점 선택 화면으로 이동)

      2. 업주 PC
        1) 제휴점 선택 화면에서 [추가 제휴 신청하기] 버튼 삭제
        2) 상단 > 내계정 > [제휴신청] 버튼 클릭 시 `제휴점 신청` 화면 으로 이동
            - 사업주, 웹마스터(영업사원)만 [제휴신청] 버튼 노출
        3) 웹마스터 계정으로 `제휴 신청` 시 현재 접속 중인 숙소의 식별키 전달
            - 본사 승인 시 접속 중인 숙소의 식별 키로 소유주(사업주) 지정 처리

      */

      if(serviceStorage.getMotels(identity)[0].affiliateStatus === 'AFFILIATION_ON_PROGRESS'){
        userStatus = USER_STATUS_WAIT_AllIANCE;
      } else if(serviceStorage.getMotels(identity)[0].affiliateStatus === 'FIRST_AFFILIATION_COMPLETE'){
        userStatus = USER_STATUS_FIRST_LOGIN;
      }



      isStartService = true;
      routeNext = { pathname: "/" };
      motelKey = serviceStorage.getMotels(identity)[0].key;
    } else {
      // 회원 가입은 되었으나, 모텔정보가 없어 승인 상태로 판단한다.
      userStatus = USER_STATUS_REQUIRED;
      routeNextLogout = {
        pathname: "/login-404",
        search: "?reason=NONE-MOTEL",
      };
    }

    switch (userStatus) {
      case USER_STATUS_OK:
      case USER_STATUS_CHANGED_INFO:
      case USER_STATUS_FIRST_LOGIN:
        if (isOwner) {
          // 사업주 이며 최초 로그인 시
          if (userStatus === USER_STATUS_FIRST_LOGIN) {
            isShownToast = false;
            serviceStorage.notifyFirstLogin();
          }
        }

        // 랜딩 정보가 있으면, 해당 페이지로 이동 한다.
        let landing = this.landingPage;
        if (!!landing && !!landing.route && !!landing.motelKey) {
          isShownToast = true;
          messageAlert = MESSAGE_GREETING_LOGIN;
          isStartService = true;
          routeNext = landing.route;
          motelKey = landing.motelKey;
        }
        landing = null;

        break;
      case USER_STATUS_REQUIRED:
        // 사업주 이며 아직 제휴 신청을 완료 하지 않았을 경우
        if (isOwner) {
          if (SERVICE_TYPE_PC) {
            routeNext = { pathname: "/signup/alliance/none" };
            routeNextLogout = null;
          }
        }
        break;
      case USER_STATUS_WAIT_AllIANCE:
        // 사업주 이며 제휴 신청이 승인 대기 중일 때
        routeNext = null;
        routeNextLogout = {
          pathname: "/login-404",
          search: "?reason=WAIT",
        };
        break;
      default:
        // 미확인 상태는 전부 로그인 화면으로 이동 처리 함
        routeNext = null;
        routeNextLogout = null;
        break;
    }

    if (!!routeNext) {
      // 이동 될 라우팅 정보가 있으면 라우팅 한다.
      if (!isStartService) {
        history.replace(routeNext);
        isShownToast && this.delayShowToast(messageAlert);
        identity = motels = routeNext = routeNextLogout = null;
        return;
      } else if (isStartService && !!motelKey) {
        // 제휴점을 선택 한 상태로 설정
        serviceStorage
          .connectMotelWithPromise(motelKey)
          .then(() => {
            history.replace(routeNext);
            isShownToast && this.delayShowToast(messageAlert);
          })
          .catch((error) => {
            // 로그아웃 처리 한다.
            this.props.logoutRequest().then(() => {
              this.handleFailedAutoLogin();
              this.landingPage = null;
              openHttpRejectionPopupWithPromise(error);
            });
          })
          .finally(() => {
            identity = motels = routeNext = routeNextLogout = null;
          });
        return;
      }
    }

    // 없으면 로그아웃 처리 한다.
    this.props
      .logoutRequest()
      .then(() => {
        !!routeNextLogout && history.push(routeNextLogout);
      })
      .finally(() => {
        this.handleFailedAutoLogin();
        identity = motels = routeNext = routeNextLogout = null;
      });
  }

  handleFailLogin(error) {
    console.log(TAG, "Called handleFailLogin");

    if (
      !!error &&
      !!error.response &&
      !!error.response.data &&
      !!error.response.data.code
    ) {
      switch (error.response.data.code) {
        case "40100001": // 40100001	사용자 인증 실패 (미가입자)
          history.push({
            pathname: "/login-404",
            search: "?reason=NONE",
          });
          return;
        case "40100002": // 40100002	인증 실패 - 비밀번호 불일치
          openAlertPopupWithPromise(
            null,
            "비밀번호가 일치하지 않습니다.\n\n확인 후 다시 이용하여주세요."
          ).finally(this.handleFailedAutoLogin);
          return;
        case "40100005": //  40100005  인증 실패 - 차단된 사용자(비밀번호 횟수 초과)
          openConfirmPopupWithPromise(
            null,
            "비밀번호 5회 오류로\n사용이 제한되었습니다.\n\n비밀번호 재설정 후 이용하세요.",
            "재설정"
          )
            .then((actionType) => {
              if (actionType !== POPUP_ACTION_OK) return;
              history.replace({ pathname: "/findpass" });
            })
            .finally(this.handleFailedAutoLogin);

          return;
        case "40100006": //  40100006  관리자 차단
          openAlertPopupWithPromise(
              null,
              error?.response?.data?.errorMsg ?? "차단된 사용자 (관리자 차단)"
          ).finally(this.handleFailedAutoLogin);

          return;
        case "40010008":
          openAlertPopupWithPromise(
              null,
              "존재하지 않는 회원 정보 입니다."
          ).finally(this.handleFailedAutoLogin);
          return;
        default:
          openAlertPopupWithPromise(
        null,
              error.response.data.errorMsg
          ).finally(this.handleFailedAutoLogin);
      }
    }

    openHttpRejectionPopupWithPromise(error).finally(
      this.handleFailedAutoLogin
    );
  }

  handleFailedAutoLogin() {
    console.log(
      TAG,
      `Called handleFailedAutoLogin. this.state.isStartingAutoLogin=${this.state.isStartingAutoLogin}, this.isActive=${this.isActive}`
    );
    if (this.state.isStartingAutoLogin === true && this.isActive === true) {
      console.log(TAG, "Change isStartingAutoLogin");
      this.setState({
        ...this.state,
        isStartingAutoLogin: false,
      });
    }
  }

  render() {
    const { classes } = this.props;
    const { isStartingAutoLogin } = this.state;

    // 모바일 - 자동 로그인이 완료 될 때 까지 화면을 갱신하지 않는다.
    if (isStartingAutoLogin) return null;

    return (
      <div className={classes.root}>
        <FooterContainer>
          <Footer />
        </FooterContainer>
        <ContainerSinglePage>
          <Box justifyContent="center">
            <div className="main">
              <Box align="center">
                <img src={`${ROOT_PATH}${configApp.ICON_LOGIN}`} />
              </Box>
              <LoginTemplate onSubmit={this.handleOnSubmit} />
            </div>
          </Box>
        </ContainerSinglePage>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    status: state.authentication.login.status,
    tempToken : state.authentication.tempToken,
    rsaKey : state.rsa.rsaKey,
    publicKey : state.rsa.publicKey
  }),
  (dispatch) =>
    bindActionCreators(
      { postTempToken, postRSA, loginRequest, loginRequestByToken, logoutRequest, openSnackbar, setRsaInitial },
      dispatch
    )
)(withStyles(styles)(LoginPageLayout));

/**
 * 본 모듈은 사용자 아이디의 형식이 유효한지 체크 하는 모듈 입니다<p/>
 *
 * @module utils/checkUserId
 */

import InputFieldGuide from "constant/InputFieldGuide";
import isEmpty from "./isEmpty";
import isUserIdFormat from "./isUserIdFormat";

/**
 * 사용자 아이디를 검사하여, 유효하지 않을 경우 에러 메세지를 반환 합니다
 *
 * @param {string} userId - 아이디
 * @return {string} 에러 메세지. 유효 할 경우 빈문자열을 반환 함
 */

export default function checkUserId(userId) {
  let errorMessage = "";
  let strUserId = userId.trim();

  // 아이디 최소 길이 검사 (최소수 6자)
  if (!errorMessage && 6 > strUserId.length) {
    errorMessage = InputFieldGuide.userId.minLength;
  }

  // 아이디 최대 길이 검사 (최소수 100자)
  if (!errorMessage && 100 < strUserId.length) {
    errorMessage = InputFieldGuide.userId.maxLength;
  }

  // 아이디 공백 검사
  if (!errorMessage && isEmpty(strUserId) === true) {
    errorMessage = InputFieldGuide.userId.required;
  }

  // 아이디 형식 검사
  if (!errorMessage && isUserIdFormat(strUserId) === false) {
    errorMessage = InputFieldGuide.userId.invalid;
  }

  return errorMessage;
}

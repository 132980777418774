/**
 * 본 모듈은 사용자 이름의 형식이 유효한지 체크 하는 모듈 입니다<p/>
 *
 * checkAccountName와 처리 플로우는 동일하나<br/>
 * 문구 관리를 InputFieldGuide에서 처리 하기 위해 별도 모듈로 운용 합니다.
 *
 * @module utils/checkUserName
 * @see module:utils/checkAccountName
 * @see module:constants/InputFieldGuide
 */

import InputFieldGuide from "constant/InputFieldGuide";
import isEmpty from "./isEmpty";
import isKoreanFormat from "./isKoreanFormat";

/**
 * 사용자 이름을 검사하여, 유효하지 않을 경우 에러 메세지를 반환 합니다
 *
 * @param {string} userName - 사용자 이름
 * @return {string} 에러 메세지. 유효 할 경우 빈문자열을 반환 함
 */

export default function checkUserName(userName) {
  let errorMessage = "";
  let strUserName = userName.trim();

  // 이름 공백 검사
  if (!errorMessage && isEmpty(strUserName) === true) {
    errorMessage = InputFieldGuide.userName.required;
  }

  // 이름 길이 검사 (최소 2글자 이상)
  if (!errorMessage && 2 > strUserName.length) {
    errorMessage = InputFieldGuide.userName.minLength;
  }

  // 이름 형식 검사
  // if (!errorMessage && isKoreanFormat(strUserName) === false) {
  //   errorMessage = InputFieldGuide.userName.invalid;
  // }

  // 이름 입력은 한글, 영문, 빈칸 허용 하므로,
  // 영문, 한글, 빈칸을 없애고 공백이 아닐 경우 에러 처리 함
  if (!errorMessage) {
    if (!!strUserName.replace(/[a-zA-Z가-힣]/gi, "").trim()) {
      errorMessage = InputFieldGuide.userName.invalid;
    }
  }

  return errorMessage;
}
